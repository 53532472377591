import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import zetrafilm from "../../images/film-2.mp4"



// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Zetra International is a financial service provider specialized in M&A.
                    <p/>
                    <a href="http://www.distylerie.com/" target="_blank">distylerie</a> and <LinkABOUT location={location}>I</LinkABOUT> created this image movie for Zetra to show in presentations and on various platforms.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                  Zetra International ist eine Finanzdienstleister spezialisiert auf M&A.
                  <p/>
                  <a href="http://www.distylerie.com/" target="_blank">distylerie</a> und <LinkABOUT location={location}>Ich</LinkABOUT> erschufen diesen Imagefilm, den Zetra in Präsentationen und Kommunikationskanälen zeigt.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>


    <video  autoPlay loop muted>
<source src={zetrafilm} type="video/mp4" />
Your browser does not support the video tag.
</video>




      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
